import React, { useEffect } from 'react'

const HubspotForm = ({ hubspotFormID, onSubmitted }) => {
	useEffect(() => {
		const script = document.createElement('script')
		script.src = `https://js.hsforms.net/forms/v2.js`
		document.body.appendChild(script)

		script.addEventListener('load', () => {
			if (window.hbspt) {
				window.hbspt.forms.create({
					portalId: process.env.HUBSPOT_TRACKING_CODE,
					formId: hubspotFormID,
					target: '#hubspotForm',
					onFormSubmitted: onSubmitted,
				})
			}
			// Pseudo-jQuery for Hubspot callbacks
			;(function (s) {
				window.jQuery =
					window.jQuery ||
					function (nodeOrSelector) {
						if (typeof nodeOrSelector == 'string') {
							return document.querySelector(s)
						}
						return nodeOrSelector
					}
			})()
		})
	}, [hubspotFormID, onSubmitted])

	return <div id='hubspotForm' />
}

export default HubspotForm
