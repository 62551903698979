import React from 'react'
import { graphql } from 'gatsby'

import CMS from '../../components/CMS'
import SEO from '../../components/SEO'
import Form from '../../components/HubspotForm'
import Layout from '../../layout/BasicPage'

import './HubspotForm.scss'

const HubspotForm = ({ data, location }) => {
	const { form } = data

	return (
		<Layout width='narrow'>
			<SEO {...form.seo.page.metaData} href={location.href} />
			<div className='hubspot-form'>
				<h1 className='title'>{form.cms.data.title}</h1>
				<div className='hubspot-form--info'>
					<CMS.Image {...form.page.data.photo} />
					<CMS.Body {...form} />
				</div>
				<Form hubspotFormID={form.page.data.hubspotFormID} />
			</div>
		</Layout>
	)
}

export default HubspotForm

export const query = graphql`
	query HubspotForm($relativePath: String) {
		form: file(relativePath: { eq: $relativePath }) {
			...SEOFragment
			...CMSFragment
			page: childMarkdownRemark {
				data: frontmatter {
					title
					hubspotFormID
					description
					photo {
						...CMSImageFragment
					}
				}
			}
		}
	}
`
